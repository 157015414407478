<template>
  <g :fill="color" fill-rule="nonzero" transform="translate(-8, -8) scale(0.075, 0.075)">
    <g>
      <path
        :fill="color"
        d="M289.48,144.64c11.08,3.94,10.08,20.43-2.5,20.05-11.7-.35-12.11-16.53-1.49-20.05h3.99Z"
      />
      <path
        :fill="color"
        d="M203.65,352.23c5.09-31.95,37.31-50.61,64.2-63.05,1.69-.78,8.24-2.59,8.54-2.93.75-.86-1.19-12.6,6.04-12.96.83-1.16-3.77-4.78-.82-9.32.69-1.06,2.4-1.12,2.84-2.34.22-.61,1.2-7.16,1.12-7.57-10.58-3.11-10.37-19.34,1.4-19.69,12.57-.37,13.55,16.04,2.42,19.69-.08.41.9,6.96,1.12,7.57.44,1.22,2.16,1.29,2.84,2.34,2.95,4.54-1.65,8.16-.82,9.32,7.23.35,5.29,12.09,6.04,12.96.3.34,6.85,2.15,8.54,2.93,26.89,12.44,59.11,31.1,64.2,63.05h32.94v14.47c-1.89,2.46-5.25,1.31-7.99,1.49l-.43,7.06-5.56.93v208.59l5.85,1.14.13,6.85,7.55.93-.5,15.6-42.32.6c-13.67,16.8-33.14,29.31-52.89,37.94-3.95,1.73-16.9,7.35-20.29,7.53-2.98.15-17.26-5.9-20.97-7.53-19.74-8.63-39.21-21.13-52.89-37.94l-42.32-.6-.5-15.6,7.55-.93.13-6.85,5.85-1.14v-208.59l-5.56-.93-.43-7.06c-2.74-.18-6.1.97-7.99-1.49v-14.47h32.94ZM239.58,571.81v-144.22c0-5.16-6.27-22.85-8.8-28.13-.99-2.07-6.78-12.76-8.66-12.29-5.35,7.11-9.47,15.26-12.2,23.74-.99,3.09-4.28,14.27-4.28,16.68v144.22h33.93ZM317.42,571.81v-140.23c0-5.64-7.37-20.16-10.75-25.18-2.54-3.77-13.22-16.27-16.64-18.29-1.05-.62-1.58-1.14-3-1-3.08.3-16.82,16.37-19.14,19.89-3.23,4.9-10.35,19.14-10.35,24.58v140.23h59.88ZM369.32,571.81v-144.22c0-5.16-6.27-22.85-8.8-28.13-.99-2.07-6.78-12.76-8.66-12.29-5.35,7.11-9.47,15.26-12.2,23.74-.99,3.09-4.28,14.27-4.28,16.68v144.22h33.93Z"
      />
      <path
        :fill="color"
        d="M294.16,176.89c6.95,6.5,1.38,21.19-10.62,17.1-13.07-4.45-1.24-28.19,10.62-17.1Z"
      />
      <path
        :fill="color"
        d="M294.16,222.17c-11.86,11.09-23.69-12.64-10.62-17.1,12-4.09,17.57,10.6,10.62,17.1Z"
      />
    </g>
    <g>
      <path
        :fill="color"
        d="M545.27,144.64c8.11,2.89,7.38,14.95-1.83,14.68-8.57-.26-8.86-12.1-1.09-14.68h2.92Z"
      />
      <path
        :fill="color"
        d="M482.43,296.62c3.73-23.39,27.32-37.05,47-46.16,1.24-.57,6.04-1.9,6.25-2.15.55-.63-.87-9.23,4.42-9.48.61-.85-2.76-3.5-.6-6.82.5-.77,1.76-.82,2.08-1.72.16-.45.88-5.24.82-5.54-7.74-2.28-7.59-14.16,1.03-14.41,9.2-.27,9.92,11.74,1.77,14.41-.06.3.66,5.09.82,5.54.32.89,1.58.94,2.08,1.72,2.16,3.32-1.21,5.97-.6,6.82,5.29.26,3.87,8.85,4.42,9.48.22.25,5.02,1.57,6.25,2.15,19.68,9.11,43.27,22.77,47,46.16h24.11v10.59c-1.38,1.8-3.84.96-5.85,1.09l-.31,5.17-4.07.68v152.71l4.29.83.09,5.02,5.53.68-.37,11.42-30.98.44c-10.01,12.3-24.26,21.46-38.72,27.77-2.89,1.27-12.37,5.38-14.86,5.51-2.18.11-12.64-4.32-15.35-5.51-14.45-6.32-28.71-15.47-38.72-27.77l-30.98-.44-.37-11.42,5.53-.68.09-5.02,4.29-.83v-152.71l-4.07-.68-.31-5.17c-2-.13-4.47.71-5.85-1.09v-10.59h24.11ZM508.73,457.36v-105.58c0-3.78-4.59-16.73-6.44-20.59-.72-1.51-4.96-9.34-6.34-9-3.92,5.21-6.93,11.17-8.93,17.38-.73,2.26-3.14,10.45-3.14,12.21v105.58h24.84ZM565.72,457.36v-102.66c0-4.13-5.39-14.76-7.87-18.43-1.86-2.76-9.68-11.91-12.18-13.39-.77-.46-1.15-.83-2.19-.73-2.26.22-12.31,11.98-14.01,14.56-2.37,3.59-7.58,14.01-7.58,18v102.66h43.84ZM603.72,457.36v-105.58c0-3.78-4.59-16.73-6.44-20.59-.72-1.51-4.96-9.34-6.34-9-3.92,5.21-6.93,11.17-8.93,17.38-.73,2.26-3.14,10.45-3.14,12.21v105.58h24.84Z"
      />
      <path
        :fill="color"
        d="M548.69,168.25c5.09,4.76,1.01,15.51-7.78,12.52-9.57-3.26-.91-20.63,7.78-12.52Z"
      />
      <path
        :fill="color"
        d="M548.69,201.4c-8.68,8.12-17.34-9.26-7.78-12.52,8.78-2.99,12.87,7.76,7.78,12.52Z"
      />
    </g>
  </g>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
